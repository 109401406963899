<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!pack">
      <span class="loader"></span>
    </span>
    <div v-if="pack" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <package-view-global
            :pack="pack"
            @onEditPackage="editPackage"
            @onDeletePackage="deletePackage"
          />
        </tab-pane>

        <tab-pane title="gallery" id="2">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GALLERY") }}
          </span>
          <package-view-gallery :pack="pack" />
        </tab-pane>

        <tab-pane
          title="authorizations"
          id="3"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_PERMISSIONS)"
        >
          <span slot="title">
            <i class="ni ni-key-25"></i>
            {{ $t("USERS.AUTHORIZATIONS") }}
          </span>
          <package-view-authorizations :pack="pack" />
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <package-view-logs :pack="pack" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import PackageViewGlobal from "../partials/PackageViewGlobal.vue";
import PackageViewLogs from "../partials/PackageViewLogs.vue";
import PackageViewGallery from "../partials/PackageViewGallery.vue";
import PackageViewAuthorizations from "../partials/PackageViewAuthorizations.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    PackageViewGlobal,
    PackageViewGallery,
    PackageViewLogs,
    PackageViewAuthorizations,
  },

  mixins: [requestErrorMixin],

  data() {
    return {
      pack: null,
    };
  },

  computed: {},

  watch: {},

  props: {
    packageId: {
      type: [String, Number],
      required: true,
    },
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("packages/get", this.packageId);
        this.pack = this.$store.getters["packages/package"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    editPackage(pack) {
      this.$emit("onEditPackage", pack);
    },
    deletePackage(pack) {
      this.$emit("onDeletePackage", pack);
    },
  },
};
</script>
