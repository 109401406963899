<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ product.name }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_PACKAGES)"
            @click="deletePackage"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_PACKAGES)"
            @click="editPackage"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <img
        v-if="pack.picture"
        class="all-infos-image"
        :src="pack.picture"
        alt="logo"
      />

      <img
        v-else
        class="all-infos-image"
        src="/img/placeholder.jpg"
        alt="logo"
      />

      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ pack.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            <div v-html="pack.excerpt"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.PRICE") }}</dt>
          <dd>
            {{ $formatCurrency(pack.price) }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("PACKAGES.FREQUENCY") }}</dt>
          <dd>
            {{ pack.frequency }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("PACKAGES.MAXIMUM_USERS") }}</dt>
          <dd>
            {{ pack.maximum_users }}
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ROLES)"
        >
          <dt>{{ $t("PACKAGES.DEFAULT_ROLE") }}</dt>
          <dd>
            <router-link :to="$objectViewRoute(pack.default_role)">
              {{ `${pack.default_role.name}` }}
            </router-link>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.TAXES") }}</dt>
          <dd>
            <dl class="row" v-for="(tax, key) in pack.taxes" :key="key">
              <dt>{{ tax.name }}</dt>
              <dd v-if="tax.type === TAX_TYPE_PERCENTAGE">{{ tax.value }} %</dd>
              <dd v-if="tax.type === TAX_TYPE_AMOUNT">
                {{ $formatCurrency(tax.value) }}
              </dd>
            </dl>
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.RESELLER") }}</dt>
          <dd>
            <reseller :reseller="pack.reseller" />
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="pack.created_at">
            {{ $formatDate(pack.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="pack.updated_at">
            {{ $formatDate(pack.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT } from "@/constants/taxes";

export default {
  name: "package-view-global",

  components: {},

  mixins: [],

  props: ["pack"],

  data() {
    return {
      TAX_TYPE_AMOUNT: TAX_TYPE_AMOUNT,
      TAX_TYPE_PERCENTAGE: TAX_TYPE_PERCENTAGE,
    };
  },

  computed: {},

  created() {},

  methods: {
    editPackage() {
      this.$emit("onEditPackage", this.pack);
    },
    deletePackage() {
      this.$emit("onDeletePackage", this.pack);
    },
  },

  mounted() {},

  watch: {
    pack(pack) {},
  },
};
</script>
